import "core-js/modules/es.array.push.js";
import { baseActiveOfSchoolPage } from "@/api/api";
export default {
  name: "visitorList",
  data() {
    return {
      tableData: [],
      goodsCodeValue: [],
      areaValue: [],
      cityList: [],
      pageInfo: {
        pageSize: 8,
        name: '',
        state: 1,
        type: 1,
        current: 1,
        total: 0,
        active_goods_type_code1: '',
        active_goods_type_code2: '',
        province: '',
        city: ''
      }
    };
  },
  mounted() {
    this.getList();
    let data = [];
    for (let i in this.$root.cityList) {
      let data1 = {
        value: this.$root.cityList[i].value,
        label: this.$root.cityList[i].label,
        children: []
      };
      for (let j in this.$root.cityList[i].children) {
        data1.children.push({
          value: this.$root.cityList[i].children[j].value,
          label: this.$root.cityList[i].children[j].label,
          children: []
        });
      }
      data.push(data1);
    }
    this.cityList = data;
  },
  methods: {
    reset() {
      this.pageInfo.name = '';
      this.pageInfo.active_goods_type_code1 = '';
      this.pageInfo.active_goods_type_code2 = '';
      this.pageInfo.province = '';
      this.pageInfo.city = '';
      this.goodsCodeValue = [];
      this.areaValue = [];
    },
    search() {
      this.pageInfo.current = 1;
      this.getList();
    },
    seleType(e) {
      this.pageInfo.active_goods_type_code1 = e[0] || '';
      this.pageInfo.active_goods_type_code2 = e[1] || '';
    },
    seleCity(e) {
      this.pageInfo.province = e[0] || '';
      this.pageInfo.city = e[1] || '';
    },
    getList() {
      baseActiveOfSchoolPage(this.pageInfo).then(res => {
        this.pageInfo.total = parseInt(res.data.total);
        this.tableData = res.data.records;
      });
    },
    showEdit(row) {
      let data = '';
      if (row) {
        data = JSON.stringify(row);
      }
      this.$root.useRouter.push({
        path: "/school/schoolActive/activeBaseInfo",
        query: {
          Info: data
        }
      });
    },
    handleSizeChange(e) {
      //修改每页显示条数
      this.pageInfo.pageSize = e;
      this.pageInfo.current = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.current = e;
      this.getList();
    }
  }
};